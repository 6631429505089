// src/App.js
import React from 'react';
import './App.css';
import Logo from './Logo';
import Tagline from './Tagline';
import Careers from './Careers';
import Footer from './Footer'; // Import the Footer component

function App() {
  return (
    <div className="App">
      <div className="content-wrap">
        <Logo />
        <Tagline />
        <Careers />
      </div>
      <Footer /> {/* Add the Footer component */}
    </div>
  );
}

export default App;
