// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 Forth Therapeutics Ltd</p>
        <p>1 Pancras Square, C/O Sofinnova Partners, London, England, N1C 4AG</p>
        <p>Company Number: 15702694</p>
    </div>
  </footer>
  );
};

export default Footer;