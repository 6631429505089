// src/Careers.js
import React, { useEffect } from 'react';
import './Careers.css';

const Careers = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the script attributes
    script.src = 'https://forthtx.bamboohr.com/js/embed.js';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once after the component mounts

  return (
    <div className="careers">
      <h2>Careers</h2>
      <div
        id="BambooHR"
        data-domain="forthtx.bamboohr.com"
        data-version="1.0.0"
        data-departmentId=""
      ></div>
    </div>
  );
};

export default Careers;
